<template>
  <v-container fluid>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-list-item class="primary">
            <v-list-item-content>
              <v-list-item-title class="headline text-left white--text"
                >Entities</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-card-text>
            <v-row justify="end">
              <v-col sm="auto">
                <v-text-field
                  v-model="searchText"
                  prepend-inner-icon="mdi-text-box-search"
                  label="Search"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col sm="auto" class="mt-2">
                <v-checkbox
                  v-model="allEntities"
                  label="All Entities?"
                ></v-checkbox>
              </v-col>
              <v-col
                v-if="isSuperUser || isProjectCreator"
                sm="auto"
                class="mt-4"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn
                      color="primary"
                      v-on="on"
                      fab
                      depressed
                      small
                      @click="addEntity()"
                    >
                      <v-icon dark>mdi-text-box-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Add New Entity</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
          <template>
            <v-data-table
              :headers="filteredHeaders"
              :items="entities"
              :server-items-length="total"
              :loading="loading"
              :sort-by.sync="pagination.sortBy"
              :sort-desc.sync="pagination.descending"
              :page.sync="pagination.page"
              :items-per-page.sync="pagination.rowsPerPage"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 25, 50],
              }"
              class="pt-4"
              dense
            >
              <template v-slot:item.entityEdit="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn
                      small
                      color="primary"
                      v-on="on"
                      icon
                      @click="editEntity(item)"
                    >
                      <v-icon class="clickable" color="primary"
                        >mdi-pencil</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Edit Entity</span>
                </v-tooltip>
              </template>
              <template v-slot:item.subEntityEdit="{item}">
                <v-tooltip v-if="item.SubID" bottom>
                  <template v-slot:activator="{on}">
                    <v-btn
                      small
                      color="primary"
                      v-on="on"
                      icon
                      @click="editSubEntity(item)"
                    >
                      <v-icon class="clickable" color="primary"
                        >mdi-pencil</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Edit Sub-Entity</span>
                </v-tooltip>
              </template>
              <template v-slot:item.Name="{item}">{{ item.Name }}</template>
              <template v-slot:item.EntityAddress="{item}">{{
                item.EntityAddress
              }}</template>
              <template v-slot:item.SubEntityAddress="{item}">{{
                item.SubEntityAddress
              }}</template>
              <template v-slot:item.ActiveProjectCount="{item}">{{
                item.ActiveProjectCount
              }}</template>
              <template v-slot:item.TotalProjectCount="{item}">{{
                item.TotalProjectCount
              }}</template>
              <template v-slot:item.IsActive="{item}">{{
                item.IsActive ? 'Active' : 'Inactive'
              }}</template>
            </v-data-table>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- SCRIPTS -->
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import {debounce, range} from 'lodash'
export default {
  name: 'entities',
  page: {
    title: 'Entity Search',
  },
  data: () => ({
    searchText: '',
    allEntities: false,
    searchCount: 0,
    entities: [],
    search: '',

    total: 0,
    pagination: {
      page: 1,
      sortBy: 'Name',
      descending: false,
      rowsPerPage: 25,
    },
  }),
  created() {
    this.searchAuditEntities()
  },
  mounted() {},
  activated() {
    this.searchAuditEntities()
  },
  computed: {
    ...authComputed,
    division: get('division/division'),

    loading() {
      return this.searchCount > 0
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    headers() {
      return [
        {
          text: 'Entity',
          align: 'center',
          value: 'entityEdit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Sub-Entity',
          align: 'center',
          value: 'subEntityEdit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Name',
          align: 'center',
          sortable: 'true',
          value: 'Name',
          visible: true,
        },
        {
          text: 'Entity Address',
          align: 'center',
          sortable: 'true',
          value: 'EntityAddress',
          visible: true,
        },
        {
          text: 'Sub-Entity Address',
          align: 'center',
          sortable: 'true',
          value: 'SubEntityAddress',
          visible: !this.currentUser.divisionId == 4,
        },
        {
          text: 'Active Projects',
          align: 'center',
          sortable: 'true',
          value: 'ActiveProjectCount',
          visible: true,
        },
        {
          text: 'Total Projects',
          align: 'center',
          sortable: 'true',
          value: 'TotalProjectCount',
          visible: true,
        },
        {
          text: 'Active?',
          align: 'center',
          sortable: 'true',
          value: 'IsActive',
          visible: this.allEntities,
        },
      ]
    },
  },
  watch: {
    currentUser(nval, oval) {
      if (!!nval) {
        this.bounce(this)
      }
    },
    pagination: {
      handler() {
        this.bounce(this)
      },
      deep: true,
    },
    searchText: {
      handler() {
        this.bounce(this)
      },
    },
    allEntities: {
      handler() {
        this.bounce(this)
      },
    },
  },
  methods: {
    // Will need to update after the controllers will be good to go
    // addEntity(item) {
    //   this.$router.push({ name: 'addEntity', params: { id: item.EntityID } })
    // },
    loadSelectedAuditEntity: call('auditEntity/loadSelectedAuditEntity'),
    handleError: call('error/handleError'),
    searchAuditEntities() {
      if (this.currentUser) {
        if (!this.pagination.sortBy) {
          this.pagination.sortBy = 'Name'
          this.pagination.descending = false
        }
        if (!this.searchText) {
          this.searchText = ''
        }
        this.searchCount++
        const url = `auditentitiessearch?pageNum=${
          this.pagination.page - 1
        }&pageSize=${this.pagination.rowsPerPage}&sortProperty=${
          this.pagination.sortBy
        }&sortDirection=${
          this.pagination.descending ? 'desc' : 'asc'
        }&searchText=${this.searchText}&allEntities=${
          this.allEntities
        }&divisionId=${this.currentUser.DivisionID}`
        this.$axios.get(url).then(
          (res) => {
            this.pagination.rowsPerPage = res.data.Page.Size
            this.pagination.page = res.data.Page.Number + 1
            this.total = res.data.Page.TotalElements
            this.entities = res.data.AuditEntities
            this.searchCount--
          },
          (error) => {
            this.handleError(error)
            this.error = error
            console.error(error)
            this.searchCount--
          }
        )
      }
    },
    bounce: _.debounce((self) => {
      self.searchAuditEntities()
    }, 750),

    addEntity(item) {
      this.$router.push({name: 'entity'})
    },
    editEntity(item) {
      this.$router.push({
        name: 'entity',
        params: {id: item.ID},
      })
    },
    editSubEntity(item) {
      this.$router.push({
        name: 'subEntity',
        params: {subEntityId: item.SubID, entityId: item.ID},
      })
    },
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
// @import '@design';
</style>

