import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_c(VCard,[_c(VListItem,{staticClass:"primary"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline text-left white--text"},[_vm._v("Entities")])],1)],1),_c(VCardText,[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,{attrs:{"sm":"auto"}},[_c(VTextField,{attrs:{"prepend-inner-icon":"mdi-text-box-search","label":"Search","clearable":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c(VCol,{staticClass:"mt-2",attrs:{"sm":"auto"}},[_c(VCheckbox,{attrs:{"label":"All Entities?"},model:{value:(_vm.allEntities),callback:function ($$v) {_vm.allEntities=$$v},expression:"allEntities"}})],1),(_vm.isSuperUser || _vm.isProjectCreator)?_c(VCol,{staticClass:"mt-4",attrs:{"sm":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"color":"primary","fab":"","depressed":"","small":""},on:{"click":function($event){return _vm.addEntity()}}},on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-text-box-plus")])],1)]}}],null,false,3507467117)},[_c('span',[_vm._v("Add New Entity")])])],1):_vm._e()],1)],1),[_c(VDataTable,{staticClass:"pt-4",attrs:{"headers":_vm.filteredHeaders,"items":_vm.entities,"server-items-length":_vm.total,"loading":_vm.loading,"sort-by":_vm.pagination.sortBy,"sort-desc":_vm.pagination.descending,"page":_vm.pagination.page,"items-per-page":_vm.pagination.rowsPerPage,"footer-props":{
              itemsPerPageOptions: [5, 10, 25, 50],
            },"dense":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.pagination, "descending", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.pagination, "descending", $event)},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "rowsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "rowsPerPage", $event)}},scopedSlots:_vm._u([{key:"item.entityEdit",fn:function(ref){
            var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"small":"","color":"primary","icon":""},on:{"click":function($event){return _vm.editEntity(item)}}},on),[_c(VIcon,{staticClass:"clickable",attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Entity")])])]}},{key:"item.subEntityEdit",fn:function(ref){
            var item = ref.item;
return [(item.SubID)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"small":"","color":"primary","icon":""},on:{"click":function($event){return _vm.editSubEntity(item)}}},on),[_c(VIcon,{staticClass:"clickable",attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Sub-Entity")])]):_vm._e()]}},{key:"item.Name",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(item.Name))]}},{key:"item.EntityAddress",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(item.EntityAddress))]}},{key:"item.SubEntityAddress",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(item.SubEntityAddress))]}},{key:"item.ActiveProjectCount",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(item.ActiveProjectCount))]}},{key:"item.TotalProjectCount",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(item.TotalProjectCount))]}},{key:"item.IsActive",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(item.IsActive ? 'Active' : 'Inactive'))]}}])})]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }